import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  Layout,
  SEO,
  CJournalJumbotron,
  CBreadCrumb,
  CJournalLead,
  CProfileMedia,
  CJournalText,
  CWideImgMedia,
  CFloatingImg,
  CBtnList,
  LWrap,
  CQuoteBox,
  CSingleImg,
  CChapterHeading,
  CLabelTextList,
  CJournalMedia,
  CMedia,
  CTileImg,
} from "../../../components/_index";

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  console.log(data.markdownRemark);
  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: "pickup",
              path: "/pickup/",
            },
          ],
          current: {
            label: frontmatter?.title.includes("-")
              ? frontmatter?.title.split("-")[0]
              : frontmatter?.title,
          },
        }}
      />
      <CJournalJumbotron
        data={{
          label: "ザ ロイヤルパーク キャンバス 大阪北浜",
          date: "2022年12月22日",
          title: (
            <>
              “あえて”が
              <br className="u_sp" />
              旅を豊かにする、
              <br className="u_sp" />
              大阪北浜で大人のワーケーション
            </>
          ),
          img: {
            src: "/assets/images/journal/article06/kv.png",
          },
          imgSp: {
            src: "/assets/images/journal/article06/kv__sp.png",
          },
        }}
      />
      <div className="u_pb60">
        <LWrap size="small">
          <CJournalLead
            lead={
              <>
                大阪というと、思い浮かべるものは？
                <br />
                おいしいグルメにネオンひしめく繁華街、昼夜賑やかで活発な雰囲気をイメージするかもしれない。だけど、そんな大阪らしさを少しはずした“あえて”を旅のテーマにしてみると、いつもと一味も二味も違う体験ができるはずだ。今回は北浜にワーケーションへ。ザ
                ロイヤルパーク キャンバス
                大阪北浜とその周辺で、3つの“あえて”を楽しもう。
              </>
            }
          />
        </LWrap>
      </div>
      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article06/img_chapter01.png",
            },
            imgSp: {
              src: "/assets/images/journal/article06/img_chapter01__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "01",
            },
            title: (
              <>
                <span>“あえて”大阪中心地から</span>
                <span>ズラして滞在してみる</span>
              </>
            ),
          }}
        />
        <LWrap>
          <CJournalText
            data={{
              text: (
                <>
                  観光メインなら大阪駅やなんば駅周辺の繁華街に泊まるのもいいけれど、ワーケーションなら少しのズラし滞在がおすすめだ。大阪キタの中心地、大阪駅・梅田駅から電車で15分弱に位置する北浜エリアは、かつて「北の濱」と呼ばれ、水運による交流が栄え、貿易や各地の諸産物の集散地として発展した街だ。金相場会所、各種問屋、両替屋など経済・金融の関連の施設が多くあったことから長い間、証券・金融の街として賑わってきた。
                  <br />
                  しかし、ここ10年ほどで街の様子は変化し、川沿いにはおしゃれなカフェやレストランが続々とオープン。レトロでおしゃれな街として親しまれ、ちょっと落ち着いた大人な雰囲気を求めて人が集まる注目のスポットだ。
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  今回訪れたのは、そんな注目スポットにあるザ ロイヤルパーク
                  キャンバス
                  大阪北浜。賑やかな大阪もいいけれど、ゆったりとワーケーションを楽しみたいという人にうってつけの場所だ。
                </>
              ),
            }}
          />
          <CWideImgMedia
            data={[
              {
                img: {
                  src: "/assets/images/journal/article06/img_chapter01_01.png",
                  alt: "",
                },
                text: (
                  <>
                    北浜駅出口隣接という好立地で、ホテルまでのアクセスはノンストレス。
                    颯爽とチェックインを済ませて、部屋に荷物をドロップオフ。カラフルでスタイリッシュな客室内は、ポップで遊び心満載。難波（なにわ）橋のライオン像をイメージしたクッションや壁のオブジェなどどこを切り取っても絵になる。コンパクトながらワークデスクにコーヒーテーブル、寝心地の良さそうなベッドなど無駄のない快適さが心地いい。
                  </>
                ),
                caption: (
                  <>
                    2019年6月に開業。ビジネス街にありながらスタイリッシュな外観が目を惹く
                  </>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article06/img_chapter01_02.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  空間や設備だけでなく、注目したいのはアメニティ。フェイスパックやスキンケアセットなど美容系アメニティも充実している。部屋でのくつろぎに欠かせないグッズは、仕事終わりの自分を癒してあげるちょっとしたご褒美にもいいかも。チェックインして早々に気分はもう上々だ。
                </>
              ),
            }}
          />

          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article06/img_chapter01_03.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article06/img_chapter01_04.png",
                  alt: "",
                },
              },
            ]}
            caption={
              <>
                アメニティはロビーラウンジに設置された「CANVAS
                PICKUP」からセルフで、必要なものを必要なだけ。
                <br />
                <span className="u_referenceMark">
                  マグカップ、歯ブラシ立ては客室内にご用意
                </span>
              </>
            }
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article06/img_chapter01_05.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article06/img_chapter01_06.png",
                  alt: "",
                },
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  さらに、全室に美容スチーマーが完備されているのもポイントが高い。お風呂上がりや就寝前などリラックスしたい時には入念なケアを。きめ細やかなサービスや気配りがあるホテルって素敵だね。思わず笑みがこぼれる。
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article06/img_chapter02.png",
            },
            imgSp: {
              src: "/assets/images/journal/article06/img_chapter02__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "02",
            },
            title: (
              <>
                <span>仕事時間は“あえて”部屋で</span>
                <span>過ごさない</span>
              </>
            ),
          }}
        />
        <LWrap>
          <CJournalText
            data={{
              text: (
                <>
                  ワーケーションの際のホテル選びで重視したいのは、集中できる場所があるかどうか。
                  仕事がはかどるラウンジがある、と噂を耳にしていたから期待に胸を膨らませて、フロントエリア奥の「キャンバスラウンジ」へ。
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    100席を超える席数とシックで遊び心あるインテリアが特徴。広々としたラウンジ内には大きめのテーブル席からソファ席、大きな窓に面したカウンター席、さらにはテラス席まであり利用シーンや気分に合わせて選べるのだ。
                    <br />
                    ラウンジではシーズンごとにイベントも開催されているらしい。DJイベントやワークショップなど、普段は馴染みのないカルチャーとの出会いは新しい自分との出会い。仕事の後は思いっきりラウンジで夜を満喫。そんなことを想像しただけでも、ワクワクしてくる。
                    <br />
                    また、仕事のおともに欠かせないコーヒーにも一味違った楽しみがある。宿泊者限定の「コーヒー・エクスペリエンス」は自分で豆から選び、好みのブレンドをドリップできるサービスだ。
                  </p>
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <ul className="c_noteList u_mt80">
                    <li>
                      ラウンジの営業時間は6:30〜24:00。宿泊者は滞在中思い思いに過ごすことができる
                    </li>
                  </ul>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article06/img_chapter02_01.png",
              alt: "",
            }}
            caption={
              <>
                コーヒー豆は地元ロースターのもの。3種類の中から豆を選び、分量も好みで変えられる
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  挽きたての豆の香りを楽しみながら自分の手で淹れる一杯は格別の味。ちょっぴり贅沢で、なかなかできない体験は仕事の合間のリフレッシュにもいいかも。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article06/img_chapter02_02.png",
              alt: "",
            }}
          />
          <CWideImgMedia
            data={[
              {
                img: {
                  src: "/assets/images/journal/article06/img_chapter02_03.png",
                  alt: "",
                },
                text: (
                  <>
                    <p>
                      コーヒーで一息ついたら、一気に集中モードに。Wi-Fiと全テーブルにコンセントタップがあるから、通信環境やPCの充電を気にせずに仕事がはかどる。資料づくりもオンライン会議も動画編集もここでならサクサクだ。
                    </p>
                    <p>
                      部屋にこもりがちな仕事時間も、せっかく普段とは違う場所に来たのだから、自由にホテルや街を楽しみたい。あえて部屋の外へ出て過ごしてみると、いつもと違った刺激があっていいのかもしれない。
                    </p>
                  </>
                ),
              },
              {
                img: {
                  src: "/assets/images/journal/article06/img_chapter02_04.png",
                  alt: "",
                },
                text: (
                  <>
                    <p>
                      仕事に没頭していると、街はすっかり夜の姿に。
                      <br />
                      ラウンジにはカフェやアルコールを楽しむ人が集まり、ネオンライトが灯り、一気に大人なムードに包まれる。今日の仕事はもうここまでにして、さあ一杯楽しもう。
                    </p>
                    <p>
                      ONもOFFもラウンジならすぐに切り替えられるから、気軽にアルコールを楽しむこともできる。仕事も遊びも充実させる。これぞ、大人のワーケーションの醍醐味だ。
                    </p>
                  </>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article06/img_chapter02_05.png",
              alt: "",
            }}
            caption={
              <>
                ビールやカクテルなど各種アルコールメニューにおつまみや軽食などもある
              </>
            }
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article06/img_chapter03.png",
            },
            imgSp: {
              src: "/assets/images/journal/article06/img_chapter03__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "03",
            },
            title: (
              <>
                <span>“あえて”王道を外して、</span>
                <span>観光してみる</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  ワーケーションのもう一つの目的は「バケーション」。せっかくならば、街ごと楽しむのはどうだろう。大阪の繁華街まで足を延ばすのもいいけれど、北浜に滞在しているなら、今回は“あえて”少し大人な北浜に触れてみるのもいい。
                  <br />
                  ホテルから数分の土佐堀川沿いには北浜の見どころが満載だ。レトロなオフィスビルの1Fを改装したおしゃれなリバーサイドカフェや象徴的な歴史ある建物、そして街の人の憩いの場である中之島公園など。あてもなく歩いて巡るだけでも、知らなかった大阪が顔をのぞかせる。
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  散歩途中に見つけた、素敵な喫茶＆BAR「MOUNT」へふらりと立ち寄ってみる。落ち着いた店内にはコーヒーの香りが漂い、ガラス張りのテラス席からは土佐堀川とレトロな建物が佇むリバービューが眺められる。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article06/img_chapter03_01.png",
              alt: "",
            }}
            caption={
              <>人気メニューの「チョコレート揚げパン＆パリパリチョコ」</>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  目の前を緩やかに流れる川を眺めつつ、甘いものとコーヒーでホッと一息。「大阪にこんな清々しい景色があるなんて知らなかった」と新しい発見に小さな幸せを感じる。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article06/img_chapter03_02.png",
              alt: "",
            }}
          />
          <CQuoteBox>
            <h3 className="c_headingLv3">MOUNT</h3>
            <p>
              住所：大阪府大阪市中央区北浜2丁目1-17
              <br />
              Instagram：@mount_kitahama
            </p>
          </CQuoteBox>
          <CJournalText
            data={{
              text: (
                <>
                  まだまだ歩き足りないからと散歩を続けると、時計の針が止まったような懐かしさとどこか新しい北浜の魅力にどっぷりと浸かっていく。難波（なにわ）橋のライオン像を横目に川を渡り、中之島公園へ。綺麗に整備された遊歩道や川沿いのデッキには、地元の人も観光客も入り混じってのんびりとした雰囲気だ。
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article06/img_chapter03_03.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article06/img_chapter03_04.png",
                  alt: "",
                },
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  北浜のシンボルといえば、大阪市中央公会堂も欠かせないだろう。煉瓦造りの建物はネオ・ルネッサンス様式で大正時代に造られたものを再現。オレンジの煉瓦が朝は燦々と輝き、夜にはライトアップされるなど、どの時間に行っても楽しませてくれる。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article06/img_chapter03_05.png",
              alt: "",
            }}
            caption={
              <>
                大阪市中央公会堂。施設内は誰でも見学でき、周辺にカフェやレストランも充実している
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  ちょっといつもと違った場所で、いつもと違った楽しみ方をしてみる。
                  <br />
                  そんな“あえて”を選ぶ旅が知らなかった大阪の魅力をぐんと引き出し、さらには自分の感性をも刺激してくれる。たっぷり歩いた足の疲れが、仕事に観光に充実した一日を物語っているから、満たされた気分でどこか嬉しい気もするのだ。
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  旅は“あえて”でもっと豊かに。
                  <br />
                  北浜であなたもいつもと違うワーケーションを体験してみてはどうだろう？
                </>
              ),
            }}
          />
          <CSingleImg
            label="旅のおまけ"
            img={{
              src: "/assets/images/journal/article06/img_chapter03_06.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  ホテルから徒歩数分ほどに位置する、御堂筋エリアでは冬のイルミネーションが開催されている。御堂筋を彩るカラフルなイルミネーションは、冬だけの特別な風景だ。ホテル滞在中、夜の散歩で楽しむのもおすすめ。
                </>
              ),
            }}
          />
          <CQuoteBox>
            <h3 className="c_headingLv3">御堂筋イルミネーション2022</h3>
            <p>
              2022年11月3日（木・祝）〜2022年12月31日（土）
              <br />
              17:00頃 ～ 23:00
              <br />
              ※12月31日（土）のみ25:00まで
            </p>
          </CQuoteBox>
          <CJournalText
            data={{
              text: (
                <>
                  <ul className="c_noteList">
                    <li>本記事・画像は2022年12月時点のものです。</li>
                  </ul>
                </>
              ),
            }}
          />

          <p className="u_fwb u_tac u_mt80">
            ザ ロイヤルパーク キャンバス 大阪北浜
          </p>
          <CBtnList
            exClass="u_mt20"
            data={[
              {
                label: "ホテルサイト",
                color: "bgBlack",
                link: {
                  href: "https://www.royalparkhotels.co.jp/canvas/osakakitahama/",
                  blank: true,
                },
                icon: "blank",
              },
            ]}
          />
        </LWrap>
      </section>

      <div className="l_sect02 u_bgGray">
        <LWrap>
          <CBtnList
            data={[
              {
                label: "ロイヤルパークホテルズJournal",
                link: {
                  href: "/pickup/#journal",
                },
                iconBefore: true,
                icon: "arrowReverse",
              },
            ]}
          />
        </LWrap>
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
